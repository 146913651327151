import React, { useEffect, useState } from "react";
import '../styles/Investidors.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import UpdateInvestor from '../utils/Modals/UpdateInvestor';
import { GrDocumentUpdate } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import InvestidorModal from "./InvestidorModal";
import { FaEdit } from "react-icons/fa";
import { sanitizeToNumbers, removeSpecialChars, apenasNumeros, customSearch } from "../utils/Tools";

const Investidores = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedEmissor, setSelectedEmissor] = useState(null);
    const [selectedInvestidor, setSelectedInvestidor] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [investidores, setInvestidores] = useState([]);
    const [modalInvestorIsOpen, setModalInvestorIsOpen] = useState(false);
    const [detailsInvestor, setDetailsInvestor] = useState(false);
    const ITEMS_PER_PAGE = 5; // Quantidade de itens por página
    const [currentPage, setCurrentPage] = useState(1); // Página atual

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentInvestidores = investidores.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(investidores.length / ITEMS_PER_PAGE);
    const [search, setSearch] = useState("");
    const [searchedInvestidores,setSearchedInvestidores] = useState([]);
    const doSearch = (e) =>{    
        const value = e.target.value;
        setSearch(value);
        const searchMatching = (_investidor, _search) =>{
            if(_search=='') return false;
            try {
                const _numberSearch = sanitizeToNumbers(_search?.trim())?.toString()??"";
                const _searchEscaped = removeSpecialChars(_search);

                /* Cnpj */
                const cnpj = apenasNumeros(_investidor?.cnpj).toString();
                const hasCnpj = customSearch(cnpj,_numberSearch);
                if(hasCnpj>-1){
                    return true;
                }
                /* Razao Social */
                const razaoSocial = _investidor?.razaoSocial?.trim();
                const hasRazaoSocial = customSearch(razaoSocial,_searchEscaped);
                if(hasRazaoSocial>-1){
                    return true;
                }
                /* Email */
                const email = _investidor?.representantes[0]?.email?.trim()??"";
                const hasEmail = customSearch(email,_searchEscaped);
                if(hasEmail>-1){
                    return true;
                }
                /* Telefone */
                const telefone = sanitizeToNumbers(_investidor?.telefone?.trim()).toString();
                const hasTelefone = customSearch(telefone,_numberSearch);
                if(hasTelefone>-1 &&_numberSearch!==''){
                    return true;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
            return false;
        }
        let _investidores = investidores.filter((inv)=> searchMatching(inv,value));
        setSearchedInvestidores(_investidores.slice(0,5));
    }
    const cleanSearch = () =>{
        setSearchedInvestidores([]);
        setSearch('');
    }

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    disabled={currentPage === i}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    const toggleModal = () => {
        setModalInvestorIsOpen(!modalInvestorIsOpen);
    }

    const openInvestorModal = (index) => {
        console.log(index);
        setSelectedItemIndex(index);
        setSelectedInvestidor(index); //atualiza com investidor selecionado
        toggleModal();
    }
    const closeInvestorModal = () => {
        setDetailsInvestor(false);
    }

    const userRef = user.data.email;

    useEffect(() => {
        if (selectedItemIndex !== null) {
            const selectedEmissorData = investidores[selectedItemIndex];
            const investidorTransformado = transformarInvestidor(selectedEmissorData);
            setSelectedEmissor(investidorTransformado);
        } else {
            setSelectedEmissor(null);
        }
    }, [selectedItemIndex, investidores]);

    useEffect(() => {
        const fetchInvestidores = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/investidor?userRef=${userRef}`);
                setInvestidores(response.data);
            } catch (error) {
                setInvestidores([]);
                console.error('Erro ao buscar investidores:', error);
            }
        };
        fetchInvestidores();
    }, [modalInvestorIsOpen]);

    const navigate = useNavigate()

    const handleSave = (investidor) => {
        localStorage.setItem('investidor', JSON.stringify(investidor));
        window.alert('Investidor Selecionado!');
        navigate('/Emissor');
    }
    const handleDeleteInfos = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    }
    const toggleDetails = (index) => {
        setSelectedEmissor(index);
        setDetailsInvestor(true);
    }
    const handleDelete = (investidorId) => {
        const url = process.env.REACT_APP_BASE_URL;
        const headers = {
        }
        if (investidorId) {
            axios.delete(`${url}/investidor/${investidorId}`, headers)
                .then((response) => {
                    if (response.status === 204) {
                        setDeleteSuccess(true);
                        setTimeout(() => {
                            setDeleteSuccess(false);
                        }, 3000);
                        setSelectedItemIndex(null);
                        setShowConfirmation(false);

                        axios.get(`${url}/investidor?userRef=${userRef}`)
                            .then((response) => {
                                const updatedInvestidores = response.data;
                                setInvestidores(updatedInvestidores);
                            })
                            .catch((error) => {
                                console.error('Erro ao fazer a solicitação:', error);
                            });
                    } else {
                        setDeleteError(true);
                        setTimeout(() => {
                            setDeleteError(false);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Erro ao fazer a solicitação:', error);
                });
        }
    }

    function transformarInvestidor(investidor) {
        if (investidor?.nome && investidor?.nome2 && investidor?.email) {
            const investidorTransformado = {
                ...investidor,
                representantes: [
                    { nome: investidor?.nome, email: investidor?.email }
                ]
            }

            // Se 'nome2' existir, adicione um segundo representante
            if (investidor?.nome2) {
                investidorTransformado.representantes.push({
                    nome: investidor.nome2,
                    email: investidor.email
                })
            }

            return investidorTransformado;
        }

        return investidor; // Retorna o objeto original se não houver transformação
    }

    return (
        <div className="detailsInvestidor">
            <div className="divTitleInvestidor">
                <h2 className="investidorMessage1">Selecione o investidor para emitir uma Nota Comercial</h2>
                <form className="searchForm" >
                    <input type="text" placeholder="Busca" value={search} name="search" onChange={doSearch} className="inputSearch" />
                    <button type="reset" aria-hidden="false" onClick={cleanSearch} className="btnClean" >X</button>
                </form>
                {searchedInvestidores.length > 0 ? (
                    <table className="investidorTable">
                        <thead>
                            <tr>
                                <th>Razão Social</th>
                                <th>CNPJ</th>
                                <th>E-mail</th>
                                <th>Telefone</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedInvestidores.map((investidor, index) => (
                                <tr key={investidor._id}>
                                    <td>{investidor.razaoSocial.length > 20 ? investidor.razaoSocial.slice(0, 20) + '...' : investidor.razaoSocial}</td>
                                    <td>{investidor.cnpj}</td>
                                    <td>{investidor.representantes[0]?.email}</td>
                                    <td>{investidor.telefone}</td>
                                    <td>
                                        <div className="tableIcons">
                                            <button onClick={() => toggleDetails(investidor)} className="updateButton">
                                                <BsEye color="#1a2f67" size={20} />
                                            </button>
                                            <p className="confirmationMessage">
                                                <button className="deleteButton" onClick={() => handleDeleteInfos(index)}> <FaTrashCan size={20} /></button>
                                            </p>
                                            <button className="updateButton" onClick={() => openInvestorModal(investidor)}><FaEdit size={20} /></button>

                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {showConfirmation &&
                                <div className="">
                                    Confirmar exclusão de {investidores[selectedItemIndex]?.razaoSocial}?
                                    <button className="newButtonInves" onClick={() => handleDelete(investidores[selectedItemIndex]._id)}>Sim</button>
                                    <button className="newButtonInves" onClick={() => setShowConfirmation(false)}>Não</button>
                                </div>}
                        </tbody>
                    </table>

                ) : (
                    search.length? (<div className="investidorDiv1">Nenhum investidor encontrado</div>):null
                )}
                <Displayer disabled={search.length==0}>
                    {investidores.length > 0 ? (
                        <table className="investidorTable">
                            <thead>
                                <tr>
                                    <th>Razão Social</th>
                                    <th>CNPJ</th>
                                    <th>E-mail</th>
                                    <th>Telefone</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentInvestidores.map((investidor, index) => (
                                    <tr key={investidor._id}>
                                        <td>{investidor.razaoSocial.length > 20 ? investidor.razaoSocial.slice(0, 20) + '...' : investidor.razaoSocial}</td>
                                        <td>{investidor.cnpj}</td>
                                        <td>{investidor.representantes[0]?.email}</td>
                                        <td>{investidor.telefone}</td>
                                        <td>
                                            <div className="tableIcons">
                                                <button onClick={() => toggleDetails(investidor)} className="updateButton">
                                                    <BsEye color="#1a2f67" size={20} />
                                                </button>
                                                <p className="confirmationMessage">
                                                    <button className="deleteButton" onClick={() => handleDeleteInfos(index)}> <FaTrashCan size={20} /></button>
                                                </p>
                                                <button className="updateButton" onClick={() => openInvestorModal(investidor)}><FaEdit size={20} /></button>

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {showConfirmation &&
                                    <div className="">
                                        Confirmar exclusão de {investidores[selectedItemIndex]?.razaoSocial}?
                                        <button className="newButtonInves" onClick={() => handleDelete(investidores[selectedItemIndex]._id)}>Sim</button>
                                        <button className="newButtonInves" onClick={() => setShowConfirmation(false)}>Não</button>
                                    </div>}
                            </tbody>
                        </table>

                    ) : (
                        <div className="investidorDiv1">Nenhum investidor encontrado</div>
                    )}

                    <div className="pagination">
                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        {renderPageNumbers()}
                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Próximo
                        </button>
                    </div>
                </Displayer>
            </div>
            {detailsInvestor && (
                <InvestidorModal
                    selectedEmissor={selectedEmissor}
                    handleSave={handleSave}
                    onClose={closeInvestorModal}
                />
            )}
            {modalInvestorIsOpen && (
                <UpdateInvestor
                    isOpen={modalInvestorIsOpen}
                    onClose={() => {
                        setModalInvestorIsOpen(false)
                    }}
                    investorData={selectedInvestidor}
                />
            )}
        </div>
    );
}

const Displayer = ({ children, disabled=true }) => (disabled)?children:null;

export default Investidores;
