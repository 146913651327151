/** Cria Url do arquivo */
import { OPS } from "pdfjs-dist";
import MODELO_BOLETADOR from "./MODELO_BOLETADOR.xlsx"; 
const ExcelJS = require('exceljs');

async function fetchAndPrepareBuffer(url="") {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
}

async function initiateDownloadFromBuffer(buffer, name="boletagem") {
    const blob1 = new Blob([buffer], 
        { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
    // Criação de uma URL para o blob
    const url1 = URL.createObjectURL(blob1);
    // Criação de um link de download para o usuário
    const link1 = document.createElement('a');
    link1.href = url1;
    
    link1.download = `${name}.xlsx`; // Nome do arquivo
    link1.click();
    // Liberação da URL do blob
    URL.revokeObjectURL(url1);
}

function generateUnique() {
    let lastTimestamp = 0;
    let counter = 0;
    return {
        getNumber:()=>{
            const currentTimestamp = Date.now();
            if (currentTimestamp === lastTimestamp) {
                counter++;
            } else {
                counter = 0;
                lastTimestamp = currentTimestamp;
            }
            const uniqueNumber = (currentTimestamp * 100 + counter) % 10000000;
            return uniqueNumber.toString().padStart(7, '0');
        }
    };
}

export async function generateBoletadorFile(infoArray, allInfoArray) {
    const workbook = new ExcelJS.Workbook();

    const valorTotal = allInfoArray[13];
    console.log({
        "boelta":"  ",
        infoArray, allInfoArray
    })

    const bytes = await fetchAndPrepareBuffer(MODELO_BOLETADOR)
    const loaded = await workbook.xlsx.load(bytes);
    /** seleciona primeira planilha */
    const worksheet = loaded.worksheets[0]; 
    /** Informações */
    const tipoDaLiquidacao = worksheet.getRow(9).getCell('D');
    /** Dados do Fundo */
    const cnpj = worksheet.getRow(10).getCell('D');
    const dadosBancarios  = worksheet.getRow(11).getCell('D');
    const nomeDoFundo  = worksheet.getRow(12).getCell('D');
    const contaCetip  = worksheet.getRow(13).getCell('D');
    /** Dados da Operação */
    const tipoOperacaoValue  = worksheet.getRow(14).getCell('D');
    const quantidade  = worksheet.getRow(15).getCell('D');
    const precoUnitario  = worksheet.getRow(16).getCell('D');
    const mercadoSecundario  = worksheet.getRow(17).getCell('D');
    const valorDaOperacao  = worksheet.getRow(18).getCell('D');
    const direitoCreditorio  = worksheet.getRow(19).getCell('D');
    const origemRecursos  = worksheet.getRow(20).getCell('D');
    const descricaoOrigem  = worksheet.getRow(21).getCell('D');
    const retencaoDeRiscoDoCedente  = worksheet.getRow(22).getCell('D');
    const percentualDeCoobrigacao  = worksheet.getRow(23).getCell('D');
    const codigoDaOperacao  = worksheet.getRow(24).getCell('D');
    const tipoDeAtivo  =    worksheet.getRow(25).getCell('D');
    const codigodoAtivoIf  = worksheet.getRow(26).getCell('D');
    const dataVencimento  = worksheet.getRow(27).getCell('D');
    const numeroDaProposta  = worksheet.getRow(28).getCell('D');
    const taxaEmissao  = worksheet.getRow(29).getCell('D');
    const dataDeEmissao  = worksheet.getRow(30).getCell('D');
    const emissor  = worksheet.getRow(31).getCell('D');
    const indexador  = worksheet.getRow(32).getCell('D');
    const percentualDoIndexador  = worksheet.getRow(33).getCell('D');
    const taxaIndexador  = worksheet.getRow(34).getCell('D');
    const baseIndexador  = worksheet.getRow(35).getCell('D');
    const tipoDeJurosDoIndexador  = worksheet.getRow(36).getCell('D');
    const dataLiquidacao  = worksheet.getRow(37).getCell('D');
    /** Dados da Contraparte */
    const tipoDePessoa  = worksheet.getRow(38).getCell('D');
    const cnpjDaContraparte  = worksheet.getRow(39).getCell('D');
    const nomeDaContraparte  = worksheet.getRow(40).getCell('D');
    const contaCetipContraparte = worksheet.getRow(41).getCell('D');
    const bancoContraparte = worksheet.getRow(42).getCell('D');
    const agenciaSemDigito = worksheet.getRow(43).getCell('D');
    const contaContraparte = worksheet.getRow(44).getCell('D');
    const contatoContraparte = worksheet.getRow(45).getCell('D');
    const comandoContraparte = worksheet.getRow(46).getCell('D');
    const observacoesGerais = worksheet.getRow(47).getCell('D');
    
    /** Informações */
    /**OK**/ tipoDaLiquidacao.value = "TED/TEF"; /** tipoDaLiquidacao -> "CETIP", "TED/TEF", "SEM FINANCEIRO" */
    /** Dados do Fundo */
    /**OK**/ cnpj.value = infoArray.cnpjOriginador;
    /**OK**/ dadosBancarios.value = `${infoArray.bancoOrig}(${infoArray.bancoOrigNumber}) Agência ${infoArray.agency} Conta Corrente ${infoArray.conta}`;
    /**OK**/ nomeDoFundo.value = infoArray.nomeOriginador;
    contaCetip.value = "";
    /** Dados da Operação */
    /**OK**/ tipoOperacaoValue.value = "Compra"; /** tipoOperacaoValue -> "Compra", "Venda" */
    /**OK**/ quantidade.value = 1; 
    /**OK**/ precoUnitario.value = valorTotal; 
    /**OK**/ mercadoSecundario.value = "Não"; /** mercadoSecundario -> "Sim", "Não" */
    /**OK**/ valorDaOperacao.value = valorTotal;
    /**OK**/ direitoCreditorio.value = "Não"; /** direitoCreditorio -> "Sim", "Não" */
    /**OK**/ origemRecursos.value = "01-Recursos Livres"; /** origemRecursos -> "01-Recursos Livres" */
    descricaoOrigem.value = "01-Não Liberados"; /** descricaoOrigem -> "01-Não Liberados" */
    retencaoDeRiscoDoCedente.value = "Não"; /** retencaoDeRiscoDoCedente -> "Sim", "Não" */
    percentualDeCoobrigacao.value = "";
    codigoDaOperacao.value = "";
    /** codigoDaOperacao -> "CCB", "CCE", "CCI", "CDB", "Cessão Direitode Crédito",
     "CDCA", "CPR", "CRA", "CRI", "DEBENTURE", "DPGE", "DPGE2",
      "LC", "LCA", "LCI", "LF", "LFS", "LFSC", "LFSN", "NC", "RDB"
    **/
    /**OK**/ tipoDeAtivo.value = "NC"; 
    codigodoAtivoIf.value = ""; 
    dataVencimento.value =infoArray.dataDoVencimento;
    numeroDaProposta.value="1";
    taxaEmissao.value =infoArray.taxaEmissao;
    dataDeEmissao.value = infoArray.dataDeEmissao;
    emissor.value = infoArray.razaoSacado;
    indexador.value = infoArray.indexadorNome;
    percentualDoIndexador.value = infoArray.taxaIndexados;
    taxaIndexador.value = "";
    baseIndexador.value = `${infoArray} Dias`; /** baseIndexador -> "252 Dias", "360 Dias" */
    tipoDeJurosDoIndexador.value = "Exponencial"; /** tipoDeJurosDoIndexador -> "Exponencial", "Linear" */
    dataLiquidacao.value = infoArray.dataDoVencimento;
    /** Dados da Contraparte */
    tipoDePessoa.value = "Pessoa Jurídica"; /** tipoDePessoa -> "Pessoa Física", "Pessoa Jurídica" */
    cnpjDaContraparte.value = infoArray.formattedCnpj;
    nomeDaContraparte.value = infoArray.razaoSacado;
    contaCetipContraparte.value = "";
    bancoContraparte.value = `${infoArray.selectedBank}(${infoArray.bankNumber})`;
    agenciaSemDigito.value = infoArray.contaAgencySacado;
    contaContraparte.value = infoArray.contaContraparte ;
    contatoContraparte.value = "";
    observacoesGerais.value="";


    //worksheet.eachRow((row, rowNumber) => {
    //    console.log(rowNumber, row.getCell('C').value, row.getCell('D'));
    //})
    const pp  = worksheet.getRow(10).getCell("D").value;
    console.log('-------------');
    console.log(pp);
    console.log('-------------');

    const testContent = await loaded.xlsx.writeBuffer();
    
    const numberUniq = generateUnique().getNumber();

    await initiateDownloadFromBuffer(testContent,`boletagem${numberUniq}`)
    return '';
    let randomDigit = 1;
    let secondDigit = 1;
    console.log(infoArray, 'infoArray');

    const cedenteName = infoArray.razaoCedente ? infoArray.razaoCedente : '';
    const customInfo = infoArray.bancoOrigNumber ? infoArray.bancoOrigNumber.toString() : '';
    const bankName = infoArray.bancoOrig ? infoArray.bancoOrig : '';
    const agency = infoArray.agency ? infoArray.agency : '';
    const enderecoSacado = infoArray.enderecoSacado ? infoArray.enderecoSacado : '';
    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const formattedOrigName = (infoArray.nomeOriginador ? infoArray.nomeOriginador : '').toUpperCase().substring(0, 30).padEnd(30, ' ');
    const formattedSacado = ((infoArray.razaoSacado || '').toUpperCase()).substring(0, 40).padEnd(40, ' ');
    const formattedOrigNumber = ((customInfo || '').substring(0, 3)).padEnd(3, ' ') || '   ';
    const formattedBankName = (bankName ? bankName : '').toUpperCase().substring(0, 15).padEnd(15, ' ');

    const formattedAgency = (agency ? agency : '').substring(0, 6).padEnd(6, ' ');
    console.log(infoArray.newDigits, 'aoo')
    function formatarData() {
        const hoje = new Date();

        // Obtém o dia, mês e ano
        const dia = hoje.getDate();
        const mes = hoje.getMonth() + 1; // Lembrando que os meses em JavaScript são zero indexados
        const ano = hoje.getFullYear();

        // Adiciona zeros à esquerda se necessário
        const diaFormatado = dia < 10 ? `0${dia}` : dia;
        const mesFormatado = mes < 10 ? `0${mes}` : mes;

        return `${diaFormatado}/${mesFormatado}/${ano}`;
    }

    const dataFormatada = formatarData();
    console.log(dataFormatada, 'data aqui');

    const [day1, month1, year1] = dataFormatada.split('/');
    const formattedMonth1 = String(month1).padStart(2, '0');
    const formattedDay1 = String(day1).padStart(2, '0');
    const formattedYear1 = String(year1).substr(-2);

    const dataEmissao = `${formattedDay1}${formattedMonth1}${formattedYear1}` || '010223'
    let lastTimestamp = 0;
    let counter = 0;
    function generateUniqueNumber() {
        const currentTimestamp = Date.now();
        if (currentTimestamp === lastTimestamp) {
            counter++;
        } else {
            counter = 0;
            lastTimestamp = currentTimestamp;
        }
        const uniqueNumber = (currentTimestamp * 100 + counter) % 10000000;
        return uniqueNumber.toString().padStart(7, '0');
    }
    const sequencial = generateUniqueNumber();


    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0'); // Obtém o dia com dois caracteres
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Obtém o mês com dois caracteres

    const stringCodigoId = infoArray?.codigoId.toString();

    const codigoRegistro = "01";
    const codigoRemessa = "REMESSA";
    const codigoServico = "01";
    const tipoOperacao = "COBRANCA";
    const numeroInscricaoEmpresa = stringCodigoId.toUpperCase().substring(0, 20).padStart(20, '0') || `00000000000000001077`; // Substitua com o número correto
    const nomeEmpresa = formattedOrigName; // Substitua com o nome correto
    const numeroBanco = formattedOrigNumber; // Substitua com o número correto
    const nomeBanco = formattedBankName
    const dataGravacao = dataEmissao; // Substitua com a data correta
    const oito = " ".repeat(8);
    const codigoRemessaRetorno = "MX";
    const numeroConvenio = "001"; // Substitua com o número correto
    const numeroAgencia = infoArray.newDigits; // Substitua com o número correto
    const branco1 = " ".repeat(15); // Preencha com 15 espaços em branco
    const sequencialArquivo = "00000"; // Substitua com o número sequencial correto
    const branco = " ".repeat(306); // Preencha com 318 espaços em branco
    const sete = " ".repeat(7);

    //Linha 1 Começando ===================================================================================================

    const linha1 = `${codigoRegistro}${codigoRemessa}${codigoServico}${tipoOperacao}${sete}${numeroInscricaoEmpresa}${nomeEmpresa}${numeroBanco}${nomeBanco}${dataGravacao}${oito}${codigoRemessaRetorno}${numeroConvenio}${numeroAgencia}${branco1}${branco}${sequencialArquivo}${secondDigit}`;


    secondDigit++

    const nControleOriginal = infoArray.idTitulo; // '212121/0190129'
    const formattedNControle = nControleOriginal.padEnd(26, ' ');
    const nControle = formattedNControle || '007050/0001              '



    const dataVencTitulo = `${formattedDay1}${formattedMonth1}${formattedYear1}` || '      '

    const originalValor = allInfoArray[5];
    const numericValor = originalValor.replace(/[^\d]/g, '');
    const formattedValor = String(numericValor).padStart(13, '0');

    const originalValue = allInfoArray[3];
    const numericValue = originalValue.replace(/[^\d,.]/g, '');
    const [integerPart, decimalPart] = numericValue.split(',');
    const cleanedInteger = integerPart.replace(/\./g, '');
    const formattedInteger = cleanedInteger.padStart(11, '0');
    const formattedDecimal = decimalPart.padEnd(2, '0').substring(0, 2);
    const formattedValue = `${formattedInteger}${formattedDecimal}`;


    const cnpjSacado = infoArray.cnpjSacado;

    const formattedCnpjSacado = cnpjSacado.replace(/\D/g, '').padStart(14, '0');
    const nSacado = formattedCnpjSacado || '38323230000164'


    const formattedEnderecoSacado = enderecoSacado.toUpperCase().substring(0, 40).padEnd(40, ' ');


    const cepSacado = infoArray.formattedCepSacado.replace('-', '') || '00000000'

    const formattedCedenteName = cedenteName.toUpperCase().substring(0, 46).padEnd(46, ' ') || 'SMARTCRED                                    ';
    const formattedCnpj = infoArray.formattedCnpj.replace(/[.-/]/g, "").replace(/-/g, "");

    const formattedCedente = `${formattedCedenteName}${formattedCnpj}`

    const linhas2 = [];
    let primeiraIteracao = true;

    for (const subarray of calcData) {
        const dataVencimentoIteracao = `${subarray[1] < 10 ? `0${subarray[1]}` : subarray[1]}${subarray[2]}${String(subarray[3]).substr(-2)}`

        const brancoLinha2 = " ".repeat(19);
        const codigoInscricaoPagador = "02";
        const numeroInscricaoPagador = "000000000199";
        const dois = '  ';
        const numeroControle = nControle;

        const digitoVerificadorAgencia = "00";
        const contaCorrente = "00";
        const digitoVerificadorConta = "00";
        const digitoVerificadorAgenciaConta = "00";
        const identificadorComplemento = "00000";
        const usoEmpresa = "000000000000000001N";
        const usoBanco = "     0";
        const indicativoMensagemSacador = "  ";
        const remessa = "01";
        const oitoEspacos = "0".repeat(8);
        const tipoNota = "79";
        const tipoPessoaCna = '02';
        const jurosMora1 = '000000000000';
        const termoCessao = '10391              '
        const enderecoSacadoOk = formattedEnderecoSacado;
        const valorDaParcela = subarray[4]; // Substitua com o valor correto
        const valorFormatado = valorDaParcela.toFixed(2).slice(0, 12).padStart(12, '0').replace(/\./g, '');
        const valorFace = valorDaParcela.toFixed(2).slice(0, 14).padStart(14, '0').replace(/\./g, '');
        const valorAbatimento = '000000000000000';
        const tipoPessoa = '02'
        const cnpjCpf = formattedCnpjSacado;
        const nomeSacador = formattedSacado;
        const cepSacadoOk = cepSacado
        const cedenteOk = formattedCedente
        const chaveNotaOk = '                                        ';
        const sequencialRegistroOk = `0000${secondDigit.toString().padStart(2, '0')}`//Aumentar mais um a cada iteração
        const digit = `${randomDigit.toString().padStart(2, '0')}`
        const ndocumento = `01${infoArray.idDocumento}-${digit}/`;
        randomDigit++;
        secondDigit++


        const linha2 = `1${brancoLinha2}${codigoInscricaoPagador}${numeroInscricaoPagador}${dois}${numeroControle}${digitoVerificadorAgencia}${contaCorrente}${digitoVerificadorConta}${digitoVerificadorAgenciaConta}${identificadorComplemento}${usoEmpresa}${dataVencimentoIteracao}${usoBanco}${indicativoMensagemSacador}${remessa}${ndocumento}${dataVencimentoIteracao}${valorFace}${oitoEspacos}${tipoNota}N${dataEmissao}000${tipoPessoaCna}${jurosMora1}${termoCessao}${valorFormatado}${valorAbatimento}${tipoPessoa}${cnpjCpf}${nomeSacador}${enderecoSacadoOk}${infoArray.newDigits}/${digit}     ${cepSacadoOk}${cedenteOk}${chaveNotaOk}    ${sequencialRegistroOk}`;
        linhas2.push(linha2);
        primeiraIteracao = false;
    }
    const linhas2Content = linhas2.join('\n');

    const biggerEmpty = `                                                                                                                                                                                                                                                                                                                                                                                                                                                     `
    const linha3 = `9${biggerEmpty}0000${secondDigit > 9 ? '' : '0'}${secondDigit}`
    const txtContent = `${linha1}\n${linhas2Content}\n${linha3}`
    const blob = new Blob([txtContent], { type: 'text/plain' });
    // Criação de uma URL para o blob
    const url = URL.createObjectURL(blob);

    // Criação de um link de download para o usuário
    const link = document.createElement('a');
    link.href = url;
    link.download = `CB${day}${month}AE.txt`; // Nome do arquivo
    link.click();

    // Liberação da URL do blob
    URL.revokeObjectURL(url);
}
